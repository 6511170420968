import React from "react";

import Layout from "../../components/layout";
import SEO from "../../components/seo";

import Grid from "@material-ui/core/Grid";

import style from "../../components/Dashboards/style";
import {checkAuth} from "../../helpers/auth";
import Results from '../../components/DashboardResults';
import axios from "axios";
import { Button } from "@material-ui/core";
import ReportsFiltering from "../../components/ReportsFiltering";
import ScrollTopLeft from "../../components/Miscellaneous/scrollTopLeft";

class LTV extends React.Component {
    results = React.createRef();
    state = {
        config: null,
        groupBy: 1,
        additionalFilters: {
            
        }
    }
    componentDidMount() {
        checkAuth('media-planner');
        axios.get(`${process.env.SERVER_ENDPOINT}/reportsConfig/ltv`).then(response => {
            response.data.dimensions = [];
            this.setState({
                ...this.state,
                config: response.data
            })
        })
    }

    getColumns() {
        let cols = Object.assign([], this.state.config.variants[this.state.groupBy].columns);
        return cols;
    }

    groupBy(value) {
        this.setState({
            groupBy: value
        }, () => {
            this.results.current.loadData();
        })
    }

    onSelect(dimensions, additionalFilters) {
        let filters = {};
        for(let i in additionalFilters) {
            if(additionalFilters[i]) 
                filters[i] = additionalFilters[i];
        }
        this.setState({
            ...this.state,
            additionalFilters: filters
        }, () => {
            this.results.current.loadData();
        })
    }

    getFilterParams() {
        let str = '';

        if(Object.keys(this.state.additionalFilters).length > 0) {
            let index = 0;
            for(let i in this.state.additionalFilters) {
                if(index === 0) {
                    str += `?${encodeURIComponent(i)}=${this.state.additionalFilters[i]}`;
                } else {
                    str += `&${encodeURIComponent(i)}=${this.state.additionalFilters[i]}`;
                }
                
                index++;
            }
        }
        return str;
    }

    render() {
        let cols = [];
        if(this.state.config !== null)
            cols = this.getColumns();
        return (
            <Layout>
                <SEO title="Welcome"/>
                <Grid container style={style.mainContainer}>
                    <Grid className='ltv_report' container justify="center">
                    {this.state.config !== null ?
                        <div style={style.tabContainer}>
                            {this.state.config.variants.map((variant, i) => 
                                <Button key={`switch_${i}`} className={'dashboardGroup__switch-button ' + (this.state.groupBy == i ? 'active' : '')} onClick={() => this.groupBy(i)}>{variant.title}</Button>
                            )}

                            <Results
                                ref={this.results}
                                columns={cols} url={process.env.SERVER_ENDPOINT + `/reports/getLtv${this.state.config.variants[this.state.groupBy].url}${this.getFilterParams()}`} 
                                title='LTV'
                                sort={false}
                                exportCSVConfig={{
                                    name:'ltv',
                                    url: process.env.SERVER_ENDPOINT + `/reports/getLtv${this.state.config.variants[this.state.groupBy].url}${this.getFilterParams()}?`
                                }}
                                filters={{
                                    selected: this.state.selectedDimensions, 
                                    additionalFilters: this.state.additionalFilters
                                }}
                            >
                                <h3>{this.state.config.title}</h3>
                                <ReportsFiltering
                                        onSelect={this.onSelect.bind(this)}
                                        selected={{

                                        }}
                                        type="ltv"
                                        config={this.state.config}
                                />
                            </Results>
                            <ScrollTopLeft />
                        </div>
                    : null}
                    </Grid>
                </Grid>
            </Layout>
        );
    }
}

export default LTV;
